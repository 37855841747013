@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "HelveticaNeue";
        src: url("./static/assets/fonts/HelveticaNeue-Roman.woff") format("woff");
    }

    
    @font-face {
        font-family: "HelveticaNeue-Light";
        src: url("./static/assets/fonts/HelveticaNeue-Light.woff") format("woff");
    }

    @font-face {
        font-family: "HelveticaNeue-Medium";
        font-weight: "medium";
        src: url("./static/assets/fonts/HelveticaNeue-Medium.woff") format("woff");
    }

    @font-face {
        font-family: "HelveticaNeue-Italic";
        src: url("./static/assets/fonts/HelveticaNeue-Italic.woff") format("woff");
    }

    @font-face {
        font-family: "Cormorant";
        font-weight: "normal";
        font-style: "normal";
        src: url("./static/assets/fonts/Cormorant-Regular.woff") format("woff");
    }

    @font-face {
        font-family: "Cormorant-Italic";
        font-weight: "normal";
        font-style: "italic";
        src: url("./static/assets/fonts/Cormorant-Italic.woff") format("woff");
    }

    @font-face {
        font-family: "Cormorant-Light";
        font-weight: "light";
        font-style: "normal";
        src: url("./static/assets/fonts/Cormorant-Light.woff") format("woff");
    }

    @font-face {
        font-family: "Cormorant-Bold";
        font-weight: "bold";
        font-style: "normal";
        src: url("./static/assets/fonts/Cormorant-Bold.woff") format("woff");
    }
    
    img {
        user-select: none;
        touch-action: none;
        -webkit-touch-callout: none;
        pointer-events: none;
    }

    button {
        -webkit-tap-highlight-color: transparent;
    }
}

@layer components {
    .label {
        @apply font-helvetica-neue-medium tracking-widester text-white
    }

    .fade {
        position: relative;
        mask-mode: alpha;
        mask-image: linear-gradient(to top, transparent 0%, white 25%)
    }

    .scroller {
        touch-action: auto !important;
        -webkit-overflow-scrolling: touch;
    }

    .square-wrapper-h {
        @apply relative overflow-hidden
    }

    .square-wrapper-w {
        @apply relative overflow-hidden
    }

    .square-wrapper-h:before {
        @apply block pl-[100%] content-[]
    }

    .square-wrapper-w:before {
        @apply block pb-[100%] content-[]
    }

    .square-content {
        @apply absolute top-0 left-0 right-0 bottom-0
    }

    .default-button {
        @apply w-[12em] h-min transition ease-out hover:ease-in hover:duration-[100] hover:outline-offset-[0.25rem] hover:outline-[0.4rem] hover:outline hover:outline-white/20 text-center p-[0.75em] hover:bg-white hover:bg-opacity-20 active:outline-white/60 bg-black bg-opacity-10
    }

    .light-border {
        @apply border-solid border-white/50 border-2
    }

    .hard-border {
        @apply border-solid border-white border-2
    }

    .text-dvh-xs {
        @apply text-[1.5dvh]
    }

    .text-dvh-sm {
        @apply text-[2dvh]
    }

    .text-dvh-md {
        @apply text-[2.5dvh]
    }

    .text-dvh-lg {
        @apply text-[3dvh]
    }

    .text-dvh-xl {
        @apply text-[3.5dvh]
    }

    .responsive-text-xs {
        @apply text-xs md:text-sm lg:text-base xl:text-lg 3xl:text-xl
    }

    .responsive-text-sm {
        @apply text-sm md:text-base lg:text-lg xl:text-xl 3xl:text-2xl
    }

    .responsive-text-md {
        @apply text-base md:text-lg lg:text-xl xl:text-2xl 3xl:text-3xl
    }

    .responsive-text-lg {
        @apply text-lg md:text-xl lg:text-2xl
    }

    .responsive-text-xl {
        @apply text-xl md:text-2xl lg:text-3xl
    }

    .responsive-text-2xl {
        @apply text-2xl md:text-3xl lg:text-4xl
    }

    .responsive-text-3xl {
        @apply text-3xl md:text-4xl lg:text-5xl
    }

    .centered {
        @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
    }

    .menu-item {
        @apply py-[0.3em] border-solid border-white bg-opacity-0 bg-gradient-to-r from-transparent active:via-[rgba(255,255,255,0.5)] to-transparent
    }

    .menu-item {
        border-image:linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%) 2;
    }

    .fade-in {
        animation: fadeIn 1s;
        -webkit-animation: fadeIn 1s;
        -moz-animation: fadeIn 1s;
        -o-animation: fadeIn 1s;
        -ms-animation: fadeIn 1s;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(255, 255, 255, 0); 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #ffffff2f; 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #7c04a8; 
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @-o-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @-ms-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    * {
        box-sizing: border-box;
        -webkit-touch-callout: none !important;
        -webkit-user-select: none !important; /* Safari */
        -khtml-user-select: none !important; /* Konqueror HTML */
        -moz-user-select: none !important; /* Firefox */
        -ms-user-select: none !important; /* Internet Explorer/Edge */
        user-select: none !important; /* Non-prefixed version, currently */
    }

    a,
    button,
    input,
    select {
        pointer-events: auto;
    }

    .hidden {
        @apply opacity-0
    }

    .hidden {
        visibility: hidden;
        transition: visibility 0s 2s, opacity 2s linear;
    }

    .spinner {
    	animation: spinner 1s linear infinite;
        -webkit-animation: spinner 1s linear infinite;
        -moz-animation: spinner 1s linear infinite;
    	transform-box: fill-box;
    	transform-origin: 50% 50%;
    }
    @keyframes spinner {
	    100% {
		    transform: rotate(360deg);
	    }
    }
    @-moz-keyframes spinner {
	    100% {
		    -moz-transform: rotate(360deg);
	    }
    }
    @-webkit-keyframes spinner {
	    100% {
		    -webkit-transform: rotate(360deg);
	    }
    }
}