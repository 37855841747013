*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

@font-face {
  font-family: HelveticaNeue;
  src: url("HelveticaNeue-Roman.24956524.woff") format("woff");
}

@font-face {
  font-family: HelveticaNeue-Light;
  src: url("HelveticaNeue-Light.85bbbcb1.woff") format("woff");
}

@font-face {
  font-family: HelveticaNeue-Medium;
  font-weight: "medium";
  src: url("HelveticaNeue-Medium.3ddb5124.woff") format("woff");
}

@font-face {
  font-family: HelveticaNeue-Italic;
  src: url("HelveticaNeue-Italic.878ad17e.woff") format("woff");
}

@font-face {
  font-family: Cormorant;
  font-weight: "normal";
  font-style: "normal";
  src: url("Cormorant-Regular.d777d786.woff") format("woff");
}

@font-face {
  font-family: Cormorant-Italic;
  font-weight: "normal";
  font-style: "italic";
  src: url("Cormorant-Italic.e6a2aab6.woff") format("woff");
}

@font-face {
  font-family: Cormorant-Light;
  font-weight: "light";
  font-style: "normal";
  src: url("Cormorant-Light.17e7080f.woff") format("woff");
}

@font-face {
  font-family: Cormorant-Bold;
  font-weight: "bold";
  font-style: "normal";
  src: url("Cormorant-Bold.c9da71b7.woff") format("woff");
}

img {
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  -webkit-touch-callout: none;
  pointer-events: none;
}

button {
  -webkit-tap-highlight-color: transparent;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.label {
  letter-spacing: .3em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-family: HelveticaNeue-Medium;
}

.fade {
  position: relative;
  -webkit-mask-image: linear-gradient(to top, #0000 0%, #fff 25%);
  mask-image: linear-gradient(to top, #0000 0%, #fff 25%);
  -webkit-mask-source-type: alpha;
  mask-mode: alpha;
}

.scroller {
  -webkit-overflow-scrolling: touch;
  touch-action: auto !important;
}

.default-button {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
  text-align: center;
  width: 12em;
  height: min-content;
  padding: .75em;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.default-button:hover {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  outline-offset: .25rem;
  transition-duration: 100;
  outline: .4rem solid #fff3;
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.default-button:active {
  outline-color: #fff9;
}

.light-border {
  border: 2px solid #ffffff80;
}

.hard-border {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.text-dvh-xs {
  font-size: 1.5dvh;
}

.text-dvh-sm {
  font-size: 2dvh;
}

.text-dvh-md {
  font-size: 2.5dvh;
}

.text-dvh-lg {
  font-size: 3dvh;
}

.text-dvh-xl {
  font-size: 3.5dvh;
}

.responsive-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

@media (min-width: 768px) {
  .responsive-text-xs {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .responsive-text-xs {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .responsive-text-xs {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1800px) {
  .responsive-text-xs {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.responsive-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

@media (min-width: 768px) {
  .responsive-text-sm {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .responsive-text-sm {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1280px) {
  .responsive-text-sm {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1800px) {
  .responsive-text-sm {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.responsive-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (min-width: 768px) {
  .responsive-text-2xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 1024px) {
  .responsive-text-2xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.centered {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 50%;
  left: 50%;
}

.menu-item {
  --tw-border-opacity: 1;
  border-style: solid;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 0;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  padding-top: .3em;
  padding-bottom: .3em;
}

.menu-item:active {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ffffff80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.menu-item {
  border-image: linear-gradient(to right, #fff0, #ffffff80 50%, #fff0 100%) 2;
}

.fade-in {
  -ms-animation: fadeIn 1s;
  animation: 1s fadeIn;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #fff0;
}

::-webkit-scrollbar-thumb {
  background: #ffffff2f;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7c04a8;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

* {
  box-sizing: border-box;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  -khtml-user-select: none !important;
}

a, button, input, select {
  pointer-events: auto;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s 2s, opacity 2s linear;
}

.spinner {
  transform-box: fill-box;
  transform-origin: 50%;
  animation: 1s linear infinite spinner;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[10\%\] {
  bottom: 10%;
}

.bottom-\[20\%\] {
  bottom: 20%;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.right-5 {
  right: 1.25rem;
}

.right-\[1\.5dvh\] {
  right: 1.5dvh;
}

.right-\[10\%\] {
  right: 10%;
}

.right-\[2\.5dvh\] {
  right: 2.5dvh;
}

.top-0 {
  top: 0;
}

.top-5 {
  top: 1.25rem;
}

.top-\[1\.5dvh\] {
  top: 1.5dvh;
}

.top-\[2\.5dvh\] {
  top: 2.5dvh;
}

.-z-10 {
  z-index: -10;
}

.z-\[10\] {
  z-index: 10;
}

.z-\[11\] {
  z-index: 11;
}

.z-\[20\] {
  z-index: 20;
}

.z-\[21\] {
  z-index: 21;
}

.z-\[2\] {
  z-index: 2;
}

.m-0 {
  margin: 0;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-mt-\[1\.7dvh\] {
  margin-top: -1.7dvh;
}

.-mt-\[1dvh\] {
  margin-top: -1dvh;
}

.mb-\[1em\] {
  margin-bottom: 1em;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-\[2\.5dvh\] {
  margin-right: 2.5dvh;
}

.mr-auto {
  margin-right: auto;
}

.mt-\[15dvh\] {
  margin-top: 15dvh;
}

.mt-\[2\.5dvh\] {
  margin-top: 2.5dvh;
}

.mt-\[5dvh\] {
  margin-top: 5dvh;
}

.mt-auto {
  margin-top: auto;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-\[1\.5em\] {
  height: 1.5em;
}

.h-\[100dvh\] {
  height: 100dvh;
}

.h-\[15dvh\] {
  height: 15dvh;
}

.h-\[18dvh\] {
  height: 18dvh;
}

.h-\[1em\] {
  height: 1em;
}

.h-\[20dvh\] {
  height: 20dvh;
}

.h-\[28dvh\] {
  height: 28dvh;
}

.h-\[2dvh\] {
  height: 2dvh;
}

.h-\[2em\] {
  height: 2em;
}

.h-\[3\.5em\] {
  height: 3.5em;
}

.h-\[30dvh\] {
  height: 30dvh;
}

.h-\[3rem\] {
  height: 3rem;
}

.h-\[4dvh\] {
  height: 4dvh;
}

.h-\[53dvh\] {
  height: 53dvh;
}

.h-\[5dvh\] {
  height: 5dvh;
}

.h-\[60\%\] {
  height: 60%;
}

.h-\[60dvh\] {
  height: 60dvh;
}

.h-\[6dvh\] {
  height: 6dvh;
}

.h-\[7dvh\] {
  height: 7dvh;
}

.h-\[80\%\] {
  height: 80%;
}

.h-\[8dvh\] {
  height: 8dvh;
}

.h-full {
  height: 100%;
}

.h-min {
  height: min-content;
}

.h-screen {
  height: 100vh;
}

.w-1\/4 {
  width: 25%;
}

.w-\[100dvw\] {
  width: 100dvw;
}

.w-\[28dvh\] {
  width: 28dvh;
}

.w-\[3\.5em\] {
  width: 3.5em;
}

.w-\[3rem\] {
  width: 3rem;
}

.w-\[4dvh\] {
  width: 4dvh;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[5dvh\] {
  width: 5dvh;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[80dvw\] {
  width: 80dvw;
}

.w-\[83\%\] {
  width: 83%;
}

.w-\[85\%\] {
  width: 85%;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-screen {
  width: 100vw;
}

.min-w-max {
  min-width: max-content;
}

.max-w-min {
  max-width: min-content;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.touch-none {
  touch-action: none;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.grid-rows-\[1fr_auto\] {
  grid-template-rows: 1fr auto;
}

.grid-rows-\[auto_1fr_auto\] {
  grid-template-rows: auto 1fr auto;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-end {
  justify-items: end;
}

.gap-\[0\.5dvh\] {
  gap: .5dvh;
}

.gap-\[0\.5em\] {
  gap: .5em;
}

.gap-\[0\.75em\] {
  gap: .75em;
}

.gap-\[1\.25dvh\] {
  gap: 1.25dvh;
}

.gap-\[1\.5dvh\] {
  gap: 1.5dvh;
}

.gap-\[1\.5em\] {
  gap: 1.5em;
}

.gap-\[1dvh\] {
  gap: 1dvh;
}

.gap-\[1em\] {
  gap: 1em;
}

.gap-\[1rem\] {
  gap: 1rem;
}

.gap-\[2dvh\] {
  gap: 2dvh;
}

.gap-\[3\.5dvh\] {
  gap: 3.5dvh;
}

.gap-\[3dvh\] {
  gap: 3dvh;
}

.gap-\[3em\] {
  gap: 3em;
}

.gap-\[5dvh\] {
  gap: 5dvh;
}

.gap-\[6dvh\] {
  gap: 6dvh;
}

.gap-\[7dvh\] {
  gap: 7dvh;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-self-center {
  justify-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overscroll-none {
  overscroll-behavior: none;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-\[\#8900bd\] {
  --tw-border-opacity: 1;
  border-color: rgb(137 0 189 / var(--tw-border-opacity));
}

.border-\[\#a10dd9\] {
  --tw-border-opacity: 1;
  border-color: rgb(161 13 217 / var(--tw-border-opacity));
}

.border-\[\#ff0000\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 0 / var(--tw-border-opacity));
}

.border-opacity-50 {
  --tw-border-opacity: .5;
}

.bg-\[\#8a10c0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(138 16 192 / var(--tw-bg-opacity));
}

.bg-\[\#8c0abd\] {
  --tw-bg-opacity: 1;
  background-color: rgb(140 10 189 / var(--tw-bg-opacity));
}

.bg-\[\#a10dd9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(161 13 217 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-\[0\.1em\] {
  padding: .1em;
}

.p-\[0\.2em\] {
  padding: .2em;
}

.p-\[0\.3em\] {
  padding: .3em;
}

.p-\[0\.5em\] {
  padding: .5em;
}

.p-\[1\.5em\] {
  padding: 1.5em;
}

.p-\[1dvh\] {
  padding: 1dvh;
}

.p-\[1em\] {
  padding: 1em;
}

.p-\[3\.5em\] {
  padding: 3.5em;
}

.p-\[5dvh\] {
  padding: 5dvh;
}

.p-\[8\%\] {
  padding: 8%;
}

.px-\[10dvw\] {
  padding-left: 10dvw;
  padding-right: 10dvw;
}

.px-\[2\.5dvw\] {
  padding-left: 2.5dvw;
  padding-right: 2.5dvw;
}

.px-\[2em\] {
  padding-left: 2em;
  padding-right: 2em;
}

.px-\[7\.5dvh\] {
  padding-left: 7.5dvh;
  padding-right: 7.5dvh;
}

.py-\[10vh\] {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.py-\[6dvh\] {
  padding-top: 6dvh;
  padding-bottom: 6dvh;
}

.py-\[8dvh\] {
  padding-top: 8dvh;
  padding-bottom: 8dvh;
}

.pb-\[12dvh\] {
  padding-bottom: 12dvh;
}

.pb-\[5em\] {
  padding-bottom: 5em;
}

.pt-\[10dvh\] {
  padding-top: 10dvh;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-cormorant {
  font-family: Cormorant;
}

.font-cormorant-bold {
  font-family: Cormorant-Bold;
}

.font-cormorant-italic {
  font-family: Cormorant-Italic;
}

.font-cormorant-light {
  font-family: Cormorant-Light;
}

.font-helvetica-neue {
  font-family: HelveticaNeue;
}

.font-helvetica-neue-italic {
  font-family: HelveticaNeue-Italic;
}

.font-helvetica-neue-light {
  font-family: HelveticaNeue-Light;
}

.font-helvetica-neue-medium {
  font-family: HelveticaNeue-Medium;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[1\.2dvh\] {
  font-size: 1.2dvh;
}

.text-\[1\.8dvh\] {
  font-size: 1.8dvh;
}

.text-\[1dvh\] {
  font-size: 1dvh;
}

.text-\[2dvh\] {
  font-size: 2dvh;
}

.text-\[4dvh\] {
  font-size: 4dvh;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.leading-\[2dvh\] {
  line-height: 2dvh;
}

.leading-none {
  line-height: 1;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-\[\#8900bd\] {
  --tw-text-opacity: 1;
  color: rgb(137 0 189 / var(--tw-text-opacity));
}

.text-\[\#a10dd9\] {
  --tw-text-opacity: 1;
  color: rgb(161 13 217 / var(--tw-text-opacity));
}

.text-\[\#ff0000\] {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.decoration-white\/50 {
  text-decoration-color: #ffffff80;
}

.decoration-1 {
  text-decoration-thickness: 1px;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.underline-offset-8 {
  text-underline-offset: 8px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 768px) {
  .md\:text-dvh-md {
    font-size: 2.5dvh;
  }
}

.hover\:bg-\[\#a10dd9\]:hover, .active\:bg-\[\#a10dd9\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(161 13 217 / var(--tw-bg-opacity));
}

.active\:bg-opacity-20:active {
  --tw-bg-opacity: .2;
}

@media (min-width: 768px) {
  .md\:visible {
    visibility: visible;
  }

  .md\:invisible {
    visibility: hidden;
  }

  .md\:w-\[56\%\] {
    width: 56%;
  }
}

@media (min-width: 1024px) {
  .lg\:w-\[48\%\] {
    width: 48%;
  }
}

@media (min-width: 1280px) {
  .xl\:w-\[40\%\] {
    width: 40%;
  }
}

/*# sourceMappingURL=index.17169326.css.map */
